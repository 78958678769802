import React from "react"
import Seo from "../../../components/seo"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import CareersData from "../../../content/careers/marketing-automation-expert/index.json"
import DynamicForm from "../../../components/forms/dynamic-form"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import ParagraphBlock from "../../../components/misc/paragraph-block";
import ImageBlock from "../../../components/misc/image-block";
import Layout from "../../../components/layout-secondary";

const MarketingAutomationExpertPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={CareersData[intl.locale].title} description={CareersData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`secondary`} content={CareersData[intl.locale]} />
        <div className={`careers`}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className={`careers-content`}>
                            {CareersData[intl.locale].content.map((c, i) => {
                                if (c.type === "image-block") {
                                    return (
                                        <div key={i}>
                                            <ImageBlock content={c} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i}>
                                            <ParagraphBlock content={c} />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`careers-form`}>
                                <DynamicForm type={`jobApplication`} defaultValues={{vacancy: `marketing-automation-expert`}} redirect={`/forms/thank-you/`} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
)

export default injectIntl(MarketingAutomationExpertPage)
